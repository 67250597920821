import Footer from "components/common/Footer";
import { HeaderV2 } from "./HeaderV2/HeaderV2";

const Layout = ({ children, hideHeaderOnMobile }) => {
  return (
    <>
      <div className={hideHeaderOnMobile ? "hidden lg:block" : ""}>
        <HeaderV2 />
      </div>
      <main
        className={`flex-1 h-screen ${
          hideHeaderOnMobile ? "lg:pt-[74px]" : "pt-[74px]"
        }`}
      >
        {children}

        <a href="https://evolved-fawn-widely.ngrok.app">Diagnostics</a>

        <Footer />
      </main>
    </>
  );
};

export default Layout;
